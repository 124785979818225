<template>
  <div v-html="editorDescription" class="editor"></div>
</template>

<script>
export default {
  name: "Editor",
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    editorDescription() {
      if (this.content) {
        return this.content.replace(/\r?\n/g, "<br>");
      }
      return "";
    }
  }
}
</script>

<style scoped>
.editor img {
  height: inherit;
}
</style>