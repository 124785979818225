<template>
  <Header/>
  <keep-alive>
    <router-view :key="$route.fullPath" v-if="$route.meta.keepalive"/>
  </keep-alive>
  <router-view :key="$route.fullPath" v-if="!$route.meta.keepalive"/>
  <Footer/>

</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
}
</script>

<style>
@import "assets/font/iconfont.css";
@import "assets/css/main.css";
</style>
