<template>
<div class="all">
  <div class="header middle">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/img/logo.png">
      </router-link>
    </div>
    <div class="top-right">

      <div class="navBar">
        <button class="navbar-toggle" @click="isMenu = !isMenu">
          <span class="navbar-toggle-icon"></span>
          <span class="navbar-toggle-icon"></span>
          <span class="navbar-toggle-icon"></span>
        </button>
        <div class="navBarList" :class="{'menuOpen':!isMenu}">
          <nav class="headerUl">
            <ul>
              <li :class="{'active': $route.name === 'About'}">
                <router-link to="/about">{{ $t("about") }}</router-link>
              </li>
              <li :class="{'active': $route.name === 'Products' || $route.name === 'ProductsList'  || $route.name === 'Details'}">
                <router-link to="/products" >{{ $t("product") }}</router-link>
              </li>
              <li :class="{'active': $route.name === 'News'}">
                <router-link to="/catalogs" >{{ $t("news") }}</router-link>
              </li>
              <li :class="{'active': $route.name === 'Contact'}">
                <router-link to="/contact" >{{ $t("contact") }}</router-link>
              </li>
            </ul>
          </nav>

          <div class="m-lang">
            <span @click="changeLanguage('en')" :class="{active:language === 'en'}">ENG</span> 丨
            <span @click="changeLanguage('hk')" :class="{active:language === 'hk'}">繁体</span>
          </div>
        </div>
      </div>

      <div class="lang">
        <span @click="changeLanguage('en')" :class="{active:language === 'en'}">ENG</span> 丨
        <span @click="changeLanguage('hk')" :class="{active:language === 'hk'}">繁体</span>
      </div>


    </div>

  </div>
</div>
  <div class="block"></div>

</template>

<script>
import i18n from "@/lang/index";
import { ref} from 'vue';

export default {
  name: "Header",
  setup() {
    const sidebarOpen = ref(false);
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    return {
      sidebarOpen,
      toggleSidebar,
    };
  },
  data() {
    return {
      language: localStorage.getItem("language") || 'en',
      isMenu: true,
      isLinkActive: false,
      showSubMenu: false,
    };
  },
  methods: {
    changeLanguage(language) {
      localStorage.setItem("language", language);
      if (language === "hk") {
        i18n.global.locale = "hk";
        localStorage.setItem('language', "hk");
        console.log(i18n.global.locale);
        location.reload();
      } else if (language === "en") {
        i18n.global.locale = "en";
        localStorage.setItem('language', "en");
        location.reload();
      }
    },
  },
  watch: {
    //点击隐藏下拉
    $route(to){
      if (document.body.clientWidth < 1023){
        console.log(document.body.clientWidth)
        this.isMenu = true
      }
      if (to.name === "Video_Category" || to.name === "District"){
        this.isLinkActive = true
      }else {
        this.isLinkActive = false
      }
    }
  }
};
</script>

<style scoped>
.all{
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 90px;
  background: #fff;
  /*不固定宽度需要的代码*/
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid #ddd;
}
.block{
  height: 100px;
}
.logo img{
  height: 80px;
}
.navbar-toggle-icon{
  width: 24px;
  height: 2px;
  background-color: #333;
  display: block;
  margin: 5px 0;
}

.logoImg img {
  height: 50px;
}

.headerUl>ul {
  display: flex;
  align-items: center;
}

.headerUl ul li{
  height: 100px;
  margin:0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerUl ul li.active{
  color: var(--background);
  border-top: 4px solid var(--background);
}
.headerUl ul li.active,
.headerUl ul li.active a,
.nav-have a.active{
 color: var(--background);
}

.headerUl>ul>li>a, .nav-have a{
  display: inline-block;
  line-height: inherit;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.headerUl ul li:last-child a{
  padding-right: 0;
}
.headerUl .iconfont{
  font-size: 12px;
  margin-left: 5px;
}

.lang{
  height: 100px;
  font-size: 14px;
  margin-left: 50px;
  padding-left: 50px;
  border-left: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.lang span{
  padding-left: 10px;
  cursor: pointer;
}
.lang span:first-child{
  margin-right: 10px;
}

.navbar-toggle {
  display: none;
}
.m-lang{
  display: none;
}

.nav-have{
  position: relative;
}
.main-panel {
  min-width: 140px;
  position: absolute;
  top:20px;
  left: 0;
  padding: 10px;
  display: none;
  z-index: 9;
}
.main-panel ul{
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  /*box-shadow: 0 0  15px #ddd;*/
}
.main-panel ul li{
  padding: 10px;
}
.nav-have:hover .main-panel {
  display: block;
}
.lang span.active, .m-lang span.active{
  color: var(--background);
  font-weight: bold;
}

.top-right{
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (max-width: 1399px) {
  .header {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1099px) {
  .headerUl>ul>li>a, .nav-have a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {

  .header{
    height: 80px;
  }
  .block{
    height: 80px;
  }
  .logo{
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .logo img{
    height: 60px;
  }
  .top-right{
    width: 100%;
  }
  .navBar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .navbar-toggle {
    display: block;
    position:relative;
    z-index: 1;
    background: transparent;
    border: none;
    padding: 0;
  }
  .navBarList {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background: #f8f8f8;
    z-index: 9;
  }

  .headerUl ul li{
    height:50px;
    margin:0;
  }
  .menuOpen {
    display: block;
    max-height: 500px;
  }

  .menuOpen{
    display: block;
    max-height: 500px;
  }

  .headerUl>ul{
    display: block;
    text-align: center;
  }
  .headerUl>ul>li{
    padding: 10px 0;
  }

  .lang{
    display: none;
  }
  .m-lang{
    display: block;
    text-align: center;
    padding: 20px;
  }

  .main-panel {
    min-width:100%;
    top:0;
    left: 0;
    display: block;
    position: relative;
  }
}

</style>