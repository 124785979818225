import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import i18n from './lang'


import MetaInfo from 'vue-meta-info';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App)
    .use(router)
    .use(VueViewer)
    .use(i18n)
    .use(MetaInfo)
    .use(ElementPlus)
    .mount('#app')

