<template>
<div class="home-banner">
  <swiper ref="swiper"
          @slideChange="onSlideChange"
          :pagination="{
           clickable: true,
            }"
      :autoplay="{
            delay:5000,
            disableOnInteraction: false,
           }"
      :loop="true"

      :modules="modules" class="mySwiper">


    <swiper-slide  v-for="(item, index) in banner" :key="index">
      <div class="banner-container">
        <div class="banner-text">
          <p v-html="editorDescription(item.content)"
             :class="{'fadeInUp': activeIndex === index}"></p>
        </div>
        <img :src="item.images">
      </div>
    </swiper-slide>
  </swiper>

</div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
// Import Swiper styles
import 'swiper/swiper-bundle.css'
// import required modules
import { Pagination,Autoplay} from "swiper";

export default {
  name: "Banner",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    banner: {
      type: Array, //返回数组
      default() {
        return []
      }
    },
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  data() {
    return {
      activeIndex:0
    };
  },
  methods: {
    onSlideChange(swiper) {
      const slidesCount = this.banner.length;
      let realIndex = swiper.realIndex;
      if (realIndex < 0) {
        realIndex = slidesCount - 1;
      } else if (realIndex >= slidesCount) {
        realIndex = 0;
      }
      this.activeIndex = realIndex;
      console.log('打印结果：', this.activeIndex);
    },
    //
    editorDescription(content) {
      if (content) {
        return content.replace(/\r?\n/g, "<br>");
      }
      return "";
    }
  }
}
</script>

<style>
.home-banner{
  overflow: hidden;
}
.home-banner .swiper {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.home-banner .swiper-slide {
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.home-banner .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*点*/
.home-banner .swiper-pagination-bullet{
  width: 30px;
  height: 2px;
  background:#fff;
  opacity: 1;
}
.home-banner .swiper-pagination{
  bottom: 30px!important;
}

.home-banner .swiper-pagination-bullet-active{
  background: var(--background);
}
/*点*/
.home-banner .swiper-pagination-fraction, .home-banner .swiper-pagination-custom, .home-banner .swiper-horizontal > .swiper-pagination-bullets, .home-banner .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 20px;
  left: 0;
}
.banner-container{
  position: relative;
}
.banner-text{
  position: absolute;
  top: 0;
  left: 60px;
  right: 60px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.banner-text p{
  width: 70%;
  color: #fff;
}

/*箭头*/
.home-banner .swiper-button-prev{
  left: 0;
}
.home-banner .swiper-button-next{
  right: 0;
}
.home-banner .swiper-button-prev, .home-banner .swiper-button-next{
  z-index: 9;
  background: var(--background-high);
  opacity: .9;
  width: 70px;
  height:70px;
}
.home-banner .swiper-button-prev:after, .home-banner .swiper-button-next:after{
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}
.home-banner .swiper-button-prev.swiper-button-disabled, .home-banner .swiper-button-next.swiper-button-disabled {
  opacity:1;
}
/*箭头*/

.fadeInUp {
  -webkit-animation: fadeInUp 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fadeInUp 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }
}

@media only screen and (min-width:0px) and (max-width:1024px) {

  .banner-text {
    bottom: 20px;
    left: 0;
    right: 0;
  }
  .banner-text p {
    font-size: 24px!important;
  }
  .banner-text p{
    width: 100%;
  }

}
@media only screen and (min-width:0px) and (max-width:767px){
  .banner-text p {
    font-size: 12px!important;
    padding: 8px;
  }
  .banner-text p span{
    font-size: 12px!important;
  }

  .home-banner .swiper-pagination-bullet{
    width: 10px!important;
    height: 10px!important;
  }
  .home-banner .swiper-pagination-fraction, .home-banner .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .home-banner .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 5px!important;
    left: 0;
  }
  .home-banner .swiper-button-prev, .home-banner .swiper-button-next{
    width: 40px;
    height:40px;
    display: none;
  }
  .home-banner .swiper-button-prev:after, .home-banner .swiper-button-next:after{
    font-size: 16px;
  }

}


</style>