<template>
  <div class="footer">
    <div class="middle common-padding-half">
      <div class="footer-c">
        <div class="footer-logo">
          <router-link to="/">
            <img src="@/assets/img/logo-bottom.png">
          </router-link>
        </div>
        <div class="footer-link">
          <h3 class="common">{{ $t("links") }}</h3>
          <ul>
            <li>
              <router-link to="/about" >{{ $t("about") }}</router-link>
            </li>
            <li>
              <router-link to="/products" >{{ $t("product") }}</router-link>
            </li>
            <li>
              <router-link to="/catalogs" >{{ $t("news") }}</router-link>
            </li>
            <li>
              <router-link to="/contact" >{{ $t("contact") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-contact-info">
          <h3 class="common">{{ $t("contact") }}</h3>

          <div class="info-item">
            <span><i class="iconfont icon-dianhua"></i></span>
            <span>{{ phone }}</span>
          </div>
          <div class="info-item">
            <span><i class="iconfont icon-fax-fill"></i></span>
            <span>{{ fax }}</span>
          </div>
          <div class="info-item">
            <span> <i class="iconfont icon-email"></i></span>
            <span>{{ email}}</span>
          </div>
          <div class="info-item">
            <span><i class="iconfont icon-dizhi"></i></span>
            <span>{{ address }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="copyright">
    <p>Copyright© {{ currentYear }} OSONG ELECTRIC CO. LTD. All Rights Reserved</p>
  </div>

</template>


<script>
import {contactInfo} from "@/network/main";
export default {
  name: "Footer",
  data(){
    return{
      currentYear: new Date().getFullYear(),
      address:'',
      phone:'',
      fax:'',
      email:'',
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      contactInfo().then(res =>{
        this.address = res.data.data.address;
        this.phone = res.data.data.phone;
        this.fax = res.data.data.fax;
        this.email = res.data.data.email;
      })
    },
  }
}
</script>

<style scoped>

.footer{
  background-color: #000;
}
.footer-c{
  display: flex;
  justify-content: space-between;
}
.footer-logo img{
  height: 80px;
}
h3.common{
  font-size: 24px;
  padding-bottom: 10px;
  color: var(--background);
}

.footer-link ul li{
  padding: 10px 0;
}
.footer-link ul li a{
  font-size: 16px;
  color: #fff;
}
.footer-link ul li a:hover{
  color: var(--background);
}

.footer-contact-info{
  width: 40%;
}
.info-item{
  display: flex;
  padding: 10px 0;
  align-items: center;
  color: #fff;
}
.info-item span i{
  padding-right: 10px;
}

.copyright{
  background-color: #000;
  border-top: 1px solid #2c2c2c;
}
.copyright p{
  text-align: center;
  padding: 20px;
  color: #fff;
  font-size: 14px;
}
.footer-link ul{
  display:flex;
  flex-wrap: wrap;
}
.footer-link ul li{
  width: 100%;
}
.footer-logo{
  margin-right: 20px;
}

@media only screen and (max-width: 992px) {
  .footer-c{
    flex-wrap: wrap;
  }
  .footer-logo, .footer-link{
    display: none;
  }
  .footer-contact-info{
    width: 100%;
  }
}

</style>