import {request} from "@/network/request";

//index
export function  indexInfo() {
    return request({
        url: 'index'
    })
}

//about
export function  aboutInfo() {
    return request({
        url: 'about_us'
    })
}

//product分类
export function  productCategories() {
    return request({
        url: 'product_type_list'
    })
}
//product list
export function  productList(product_type_id, page) {
    return request({
        url: 'product_list',
        params:{
            product_type_id,
            page,
        }
    })
}
//详情
export function  productDetails(id) {
    return request({
        url: 'product_details',
        params:{
            id
        }
    })
}
//消息列表
export function  newsList(page) {
    return request({
        url: 'news',
        params: {
            page: page
        },
    })
}
//联系我们--info
export function  contactInfo() {
    return request({
        url: 'contact_us'
    })
}
//联系我们--提交表格
export function  contactForm(params) {
    return request({
        url: 'contact_us_form',
        method: 'post',
        data: params,
    })
}
//上传图片
export function  uploadFiles(params) {
    return request({
        url: 'upload_file',
        method: 'post',
        data: params,
    })
}
//获取国家
export function  country() {
    return request({
        url: 'country'
    })
}
//提交inquiry表格
export function  inquiry(params) {
    return request({
        url: 'inquiry_form',
        method: 'post',
        data: params,
    })
}
//inquiry info
export function  inquiryInfo() {
    return request({
        url: 'inquiry',
    })
}