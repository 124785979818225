<template>
<div class="common-footer bg common-padding-half" :style="{ 'background-image': 'url(' + info.web_bottom_banner + ')' }">
   <div class="middle">
     <div class="big-title common-footer-title" >
       <h1>{{ info.web_bottom_banner_title }}</h1>
     </div>
     <div class="description">
        {{info.web_bottom_banner_description }}
     </div>
     <div class="common-btn center">
         <div>
           <router-link to="/inquiry">
             {{ $t("inquiry") }} <span><i class="iconfont icon-youjiantou"></i></span>
           </router-link>
         </div>
     </div>
   </div>
</div>
</template>

<script>
import {indexInfo} from "@/network/main";
export default {
  name: "FooterInquiry",
  data(){
    return{
      info:''
    }
  },

  created() {
    this.getIndex()
  },
  methods: {
    getIndex() {
      indexInfo().then(res => {
        this.info = res.data.data.bottom_banner;
      })
    },
  }
}
</script>

<style scoped>

.common-footer-title h1{
  justify-content: center;
  color: #fff;
}
.description{
  padding: 40px 0;
  text-align: center;
  color: #fff;
}

</style>