export default {
    home:"首頁",
    about:"關於我們",
    product:"產品",
    news:"產品目錄",
    contact:"聯繫我們",
    more:"查看更多",
    inquiry:"立即詢價",
    ourProducts:"我們的產品",
    who:"誰是OSONG",
    quotationInquiry:"報價查詢",
    address:"地址",
    tel:"電話",
    fax:"傳真",
    email:"電子郵件",
    intro:"如果您有任何問題，請聯繫我們，我們將很樂意為您解答！",
    inquiryNow:"立即咨詢",
    loading:"加載中",
    introduction:"介紹",
    links:"導航",
    contactForm: {
        name:'名稱/組織',
        telephone:'電話',
        email:'電子郵件',
        message:'留言',
        submit:'提交',
        success:"郵件發送成功！",
        tips:'請填寫正確的郵件格式',
        required:'請填寫必填項',
        error:'驗證碼不正確',
    },
    inquiryForm:{
        inquiryForm01:'立即發送詢價',
        inquiryForm02:'名稱/組織',
        inquiryForm03:'例如：請提供目錄和價目表',
        inquiryForm04:'詢價內容',
        inquiryForm05:'留言',
        inquiryForm06:'送貨國家或地區',
        inquiryForm07:'預計訂單數量',
        inquiryForm08:'相關問題',
        inquiryForm09:'詢價圖片',
        inquiryForm10:'選擇文件',
        inquiryForm11:'僅限圖片格式',
        inquiryForm12:'聯繫信息',
        inquiryForm13:'公司',
        inquiryForm14:'電子郵件',
        inquiryForm16:'聯繫人',
        inquiryForm17:'國家',
        inquiryForm18:'地址',
        inquiryForm19:'城市、州',
        inquiryForm20:'郵政編碼',
        inquiryForm21:'電話',
        inquiryForm22:'傳真',
        inquiryForm23:'網站',
        inquiryForm24:'名片',
        inquiryForm25:'性別',
        inquiryForm26:'職位',
        inquiryForm27:'第二電子郵件',
        inquiryForm28:'驗證碼',
        inquiryForm29:'發送詢價郵件',
        inquiryForm30:'選擇國家',
        inquiryForm31:'男性',
        inquiryForm32:'女性',
    },
}





