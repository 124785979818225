<template>
  <div v-if="isShow">
    <Loading/>
  </div>

  <div>
    <banner :banner="banner"></banner>
    <div class="common-padding-100">
      <div class="middle">
        <div class="big-title">
          <h1><img src="@/assets/img/gth.png">{{ $t("ourProducts") }}</h1>
        </div>
        <div class="pro-cate">
          <swiper
              :slidesPerView="1"
              :spaceBetween="10"
              :breakpoints="{
                     '320': {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      '640': {
                        slidesPerView: 2,
                        spaceBetween: 0,
                      },
                      '768': {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                      '1024': {
                        slidesPerView: 6,
                        spaceBetween:0,
                      },
                    }"
              :modules="modules"
              class="productSwiper"
          >
            <swiper-slide class="pro-cate-item"
                          v-for="(productType, index) in productTypes" :key="index"
                          @click="showProducts(productType)"
                          :class="{ 'active': currentProductType === productType }">
              <div>
                <span>0{{ index+1 }}</span>
                <h3>{{ productType  }}</h3>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="pro-list">
          <ul>
            <li class="common-item"
                v-for="(item, index) in currentProducts"
                :key="index"
                @click="productDetails(item.id, item.title)"
            >
              <div class="common-item-img">
                <img :src="item.cover">
              </div>

              <div class="common-item-txt">
                <h3>{{ item.title }}</h3>
                <p>{{ item.subheading }}</p>
              </div>
            </li>
          </ul>
        </div>

        <div class="common-btn center">
          <div>
            <router-link to="/products" >
              {{ $t("more") }} <span><i class="iconfont icon-youjiantou"></i></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="in-about bg-common common-padding-100" :style="{ 'background-image': 'url(' + about.banner + ')' }">
      <div class="middle">
        <div class="in-about-c">
          <div class="big-title">
            <h1 style="color: #fff"><img src="@/assets/img/gth.png">{{ $t("who") }}</h1>
          </div>
          <div class="about-des">
            <Editor :content="about.intro" style="color: #fff"></Editor>
            <div class="about-02">
              <div class="about-02-txt">
                <h2>{{ about.label_content_one }}</h2>
                <p>{{ about.label_one }}</p>
              </div>
              <div class="about-02-txt">
                <h2>{{ about.label_content_two }}</h2>
                <p>{{ about.label_two }}</p>
              </div>
            </div>
          </div>
          <div class="common-btn">
            <div>
              <router-link to="/about" >
                {{ $t("more") }} <span><i class="iconfont icon-youjiantou"></i></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="middle common-padding-100">
      <div class="big-title">
        <h1><img src="@/assets/img/gth.png">{{ $t("news") }}</h1>
      </div>
      <div class="news-list">
        <ul>
          <li class="common-padding-half" v-for="item in newsList" :key="item.id" >
            <a :href="item.pdf" target="_blank" >
              <img :src="item.cover">
              <h5>{{ item.date }}</h5>
              <p><b>{{item.title}}</b> </p>
              <div class="jt">
                <i class="iconfont icon-youjiantou"></i>
              </div>
            </a>
          </li>
          <li></li>
        </ul>
      </div>
      <div class="common-btn center">
        <div>
          <router-link to="/inquiry" >
            {{ $t("more") }} <span><i class="iconfont icon-youjiantou"></i></span>
          </router-link>
        </div>
      </div>
    </div>
    <FooterInquiry></FooterInquiry>
  </div>
</template>

<script>
import { ref } from 'vue'
import Banner from "../components/Banner";
import Editor from "@/components/Editor";
import FooterInquiry from "@/components/FooterInquiry";
import Loading from "@/components/loading";
import {indexInfo} from "@/network/main";

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css'


export default {
  name: "Home",
  components:{
    Banner,
    Editor,
    FooterInquiry,
    Loading,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta,
    }
  },
  metaInfo () {
    return {
      title:"OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'keywords',
          content: '测试各种关键字，关键字1'
        },{
          name: 'description',
          content: this.seo
        }
      ],
    }
  },
  data() {
    return{
      isShow:true,
      seo:'',
      about:'',
      banner:[],
      newsList:[],
      productTypes: [],
      currentProducts: [],
      products:[],
      currentProductType: null
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.getProductTypes();
    this.showProducts(this.productTypes[0]);
  },
  methods:{
    getInfo() {
      indexInfo().then(res =>{
        this.isShow = false;
        this.banner = res.data.data.banner;
        this.products = res.data.data.product;
        this.getProductTypes(); // 获取产品类型
        this.showProducts(this.productTypes[0]); // 默认显示第一个产品类型
        this.currentProductType = this.productTypes[0]; // 设置默认选中的产品类型
        this.newsList = res.data.data.news;
        this.about = res.data.data.about_us;
        this.seo = res.data.data.seo;
      })
    },
    getProductTypes() {
      this.productTypes = [...new Set(this.products.map(p => p.title))];
    },
    showProducts(productType) {
      const foundProductType = this.products.find(p => p.title === productType);
      this.currentProducts = foundProductType ? foundProductType.item : [];
      this.currentProductType = productType; // 更新当前选中的产品类型
    },
    //productDetails{
    productDetails(id, title){
      this.$router.push({ name: 'Details',  params: { id: id, title: title } });
    },
  }
}
</script>

<style scoped>

</style>