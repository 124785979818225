export default {
        home:"Home",
        about:"About Us",
        product:"Products",
        news:"Catalogs",
        contact:"Contact Us",
        more:"View More",
        inquiry:"Inquiry Form",
        ourProducts:"our products",
        who:"who is OSONG",
        quotationInquiry:"quotation inquiry",
        address:"Address",
        tel:"Tel",
        fax:"Fax",
        email:"Email",
        intro:"If you have any questions, please contact us and we'll be happy to answer them!",
        inquiryNow:"Inquiry Now",
        loading:"Loading",
        introduction:"Introduction",
        links:"Links",
        contactForm: {
                name:'Name/Organisation',
                telephone:'Telephone',
                email:'Email',
                message:'Message',
                submit:'Submit',
                success:"Mail sent successfully！",
                tips:'Please fill in the correct email format',
                required:'Please fill in the required fields',
                error:'Incorrect verification code',
        },
        inquiryForm:{
                inquiryForm01:'Send Inquiry Now',
                inquiryForm02:'Name/Organisation',
                inquiryForm03:'For example: Please offer us your catalog and price list',
                inquiryForm04:'Inquiry Content',
                inquiryForm05:'Message',
                inquiryForm06:'Delivery Country Or Area',
                inquiryForm07:'Estimated Order Quantity',
                inquiryForm08:'Related Questions',
                inquiryForm09:'Inquiry-Image',
                inquiryForm10:'Choose Files',
                inquiryForm11:'Image Format Only',
                inquiryForm12:'Contact Information',
                inquiryForm13:'Company',
                inquiryForm14:'Email',
                inquiryForm16:'Contact Person',
                inquiryForm17:'Country',
                inquiryForm18:'Address',
                inquiryForm19:'City, State',
                inquiryForm20:'Zip Code',
                inquiryForm21:'Telephone',
                inquiryForm22:'Fax',
                inquiryForm23:'Website',
                inquiryForm24:'Business Card',
                inquiryForm25:'Gender',
                inquiryForm26:'Job Title',
                inquiryForm27:'Second Email',
                inquiryForm28:'Verification Code',
                inquiryForm29:'Send Inquiry Email',
                inquiryForm30:'Select The Country',
                inquiryForm31:'Male',
                inquiryForm32:'Female',
        },
}
