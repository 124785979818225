import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      title: 'About Us - OSONG ELECTRIC Co. LTD',
      description: 'This is the about page description.'
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Products.vue')
  },
  {
    path: '/list/:id/:title',
    name: 'ProductsList',
    component: () => import('@/views/ProductsList.vue')
  },
  {
    path: '/details/:id/:title',
    name: 'Details',
    component: () => import('@/views/Details.vue')
  },
  {
    path: '/catalogs',
    name: 'News',
    component: () => import('@/views/News.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/inquiry',
    name: 'Inquiry',
    component: () => import('@/views/Inquiry.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },
})

//路由点击跳转后页面回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
